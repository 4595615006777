import * as THREE from 'three';

function createAnimation(mobile, gallery, rocket, stairs, phone, cloudOne, cloudTwo, cloudThree, sun, stars, ambientLight, mainLight, emitter) {

    gsap.registerPlugin(ScrollTrigger);

    let origin = "50% 100%";
    let top_position = false;

    if(window.scrollY === 0) {
        top_position = true;
    }

    let start_anim = gsap.timeline();

    if (mobile) {
        console.log('mobile');
        origin = "60% 100%";
        start_anim.set(".plant-footer", {opacity:1, scaleX:1.5, scaleY:1.5, transformOrigin:origin, rotationY:180});

        start_anim.to(cloudOne.position, {y: 20, duration:1, ease: "bounce.out"},0);
        start_anim.to(cloudTwo.position, {y: -60, duration:1, ease: "bounce.out"},0.2);
        start_anim.to(cloudThree.position, {y: 60, duration:1, ease: "bounce.out"},0.2);
    } else {
        start_anim.set(".plant-footer", {opacity:1, rotationX:90, transformOrigin:origin});

        start_anim.to(cloudOne.position, {y: 10, duration:1, ease: "bounce.out"},0);
        start_anim.to(cloudTwo.position, {y: -60, duration:1, ease: "bounce.out"},0.2);
        start_anim.to(cloudThree.position, {y: 50, duration:1, ease: "bounce.out"},0.2);
    }
    start_anim.to(sun.scale, {x:2.5, y:0.8, z:1.5, duration:1, ease: "bounce.out", onComplete:sunRotationStart},1);
    start_anim.to(mainLight, {intensity: 1, duration:1, ease: "none"},1.2);
    start_anim.to('.content-holder', {opacity: 1, duration:1, ease: "none"},1.2);

    //pop up grass if top of screen
    if(top_position) {
        //console.log('top_position = true');
        start_anim.to(".plant-footer", {rotationX: 0, duration: 1, transformOrigin: origin, ease: "bounce.out"}, 1.2);
    }

    //Spin the sun
    var timer = null,
    interval = 40;

    function sunRotationStart(){
        //console.log('start sun');
        //sun.rotation.z = 0;
        start_anim.to('#background-holder', {backgroundColor: "#00a6ff", duration:3, ease: "none"});
        
        if (timer !== null) return;
        timer = setInterval(function() {
            sun.rotation.z += 0.01;
            //console.log('spin');
        }, interval);
    }
    function sunRotationStop(){
        //console.log('stop sun');
        start_anim.to('#background-holder', {backgroundColor: "#000000", duration:1, ease: "none"});

        clearInterval(timer);
        timer = null
    }

    let stairs_anim = gsap.timeline()

    stairs_anim.to(cloudOne.position, {
        z: 300, ease: "none", scrollTrigger: {

            trigger: "#trigger-background",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: false

        }
    })
    stairs_anim.to(cloudTwo.position, {
        z: 300, ease: "none", scrollTrigger: {

            trigger: "#trigger-background",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: false

        }
    })
    stairs_anim.to(cloudThree.position, {
        z: 300, ease: "none", scrollTrigger: { 

            trigger: "#trigger-background",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: false

        }
    })
    
    stairs_anim.to(cloudOne.rotation, {
        y: -3, ease: "none", scrollTrigger: {

            trigger: "#trigger-background",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: false

        }
    })
    stairs_anim.to(cloudTwo.rotation, {
        x: -3, ease: "none", scrollTrigger: {

            trigger: "#trigger-background",
            scrub: 1, 

            start: "top bottom",
            end: "bottom top",
            markers: false

        }
    })
    stairs_anim.to(cloudThree.rotation, {
        x: 3, ease: "none", scrollTrigger: { 

            trigger: "#trigger-background",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: false

        }
    }) 
    stairs_anim.to(cloudOne.position, {
        x: "+150", ease: "none", scrollTrigger: { 

            trigger: "#trigger-background",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: false

        }
    }) 



    stairs_anim.to(sun.position, {
        y: 100, ease: "bounce.out", scrollTrigger: {

            trigger: "#trigger-background",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: false,
            onLeave: sunRotationStop,
            onLeaveBack: sunRotationStart
        }
    })


    stairs_anim.to(sun.rotation, {
        y: 6, ease: "bounce.out", scrollTrigger: {

            trigger: "#trigger-background",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: false,
            onLeave: sunRotationStop,
            onLeaveBack: sunRotationStart
        }
    })

    stairs_anim.to('.plant-footer', {
        rotationX: 180, transformOrigin:origin, ease: "bounce.out", scrollTrigger: {

            trigger: "#trigger-background",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: false

        }
    })

    /*stairs_anim.to(mainLight, {
        intensity: 1, ease: "power1.inOut", scrollTrigger: {

            trigger: "#trigger-lights",
            scrub: 1,

            start: "top top",
            end: "bottom top",
            markers: false

        }
    })*/

    /*stairs_anim.to(ambientLight, {
        intensity: 0.5, ease: "power1.inOut", scrollTrigger: {

            trigger: "#trigger-lights",
            scrub: 1,

            start: "top top",
            end: "bottom top",
            markers: false

        }
    })*/

    let stairsPosition, flamePosition;

    if (mobile) {
        stairsPosition = 10;
        emitter.p.x = 5;
        emitter.p.y = -47;
    } else {
        stairsPosition = 26;
        emitter.p.x = 20;
        emitter.p.y = -80;
    }

    stairs_anim.to(stairs.position, {
        x: stairsPosition, ease: "power1.inOut", scrollTrigger: {

            trigger: "#show-stairs",
            scrub: 1,

            start: "top top",
            end: "bottom top",
            markers: false

        }
    })


    stairs_anim.to(stairs.rotation, {
        y: 20, ease: "power1.inOut", scrollTrigger: {

            trigger: ".content",
            scrub: 1,

            start: "top top",
            end: "bottom top",
            markers: false

        }
    })

    stairs_anim.to(stairs.position, {
        y: -250, ease: "power1.inOut", scrollTrigger: {

            trigger: ".content",
            scrub: 1,

            start: "top top",
            end: "bottom top",
            markers: false

        }
    })

    stairs_anim.to(stars.position, {
        y: 0, ease: "power1.inOut", scrollTrigger: {

            trigger: "#trigger-background",
            scrub: 1,

            start: "top top",
            end: "bottom top",
            markers: false

        }
    })

    stairs_anim.to(stars.rotation, {
        y: 3, ease: "power1.inOut", scrollTrigger: {

            trigger: "#trigger-stars",
            scrub: 1,

            start: "top top",
            end: "bottom top",
            markers: false

        }
    })


    /* ROCKET */
    stairs_anim.to(rocket.position, {
        y: 40, ease: "none", scrollTrigger: {

            trigger: "#trigger-rocket",
            scrub: true,

            start: "top bottom",
            end: "bottom top",
            markers: false,
            onEnter: emitterStart,
            onLeaveBack: emitterStop,
            onLeave: emitterStop,
            onEnterBack: emitterStart

        }
    })

    stairs_anim.to(emitter.p, {
        y: 35, ease: "none", scrollTrigger: {

            trigger: "#trigger-rocket",
            scrub: true,

            start: "top bottom",
            end: "bottom top",
            markers: false

        }
    })

    stairs_anim.to(rocket.rotation, {
        y: 6, ease: "power1.inOut", scrollTrigger: {

            trigger: "#trigger-rocket",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: false

        }
    })


    /* GALLERY */
    stairs_anim.to(gallery.position, {
        y: 200, ease: "none", scrollTrigger: {

            trigger: "#normal-sites",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: false

        }
    })

    /* stairs_anim.to(gallery.position, {
        z: 100, ease: "power1.out", scrollTrigger: {

            trigger: "#trigger-gallery-zoom",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: true

        }
    })
    stairs_anim.to(gallery.position, {
        y: -200, ease: "power1.out", scrollTrigger: {

            trigger: "#trigger-gallery-out",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: true

        }
    })*/

    let phone_anim = gsap.timeline();
    let phonePosition;
    if (mobile) {
        phonePosition = -2;
    } else {
        phonePosition = 0;
    }
    phone_anim.to(phone.position, {
        y: phonePosition, ease: "power1.out", scrollTrigger: {

            trigger: "#trigger-phone",
            scrub: 1,
            start: "top bottom",
            end: "bottom bottom",
            markers: false,
            onEnter: startSpin,
            onLeaveBack: stopSpin
        },

    })

    function stopSpin(){
        //console.log('stop spin');
        gsap.to(phone.rotation, {duration: 0, repeat: 0, ease: "none"});
    }

    function startSpin(){
        //console.log('spin');
        gsap.to(phone.rotation, {duration: 10, y: Math.PI * 2, repeat: -1, ease: "none"});
    }


    function emitterStart(){
        //console.log('start particles');
        emitter.emit();
    }

    function emitterStop(){
        //console.log('stop particles');
        emitter.stopEmit();
    }
 
    /*stairs_anim.to('#background-holder', {
        backgroundImage:"linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)", ease: "none", scrollTrigger: {

            trigger: "#trigger-rocket",
            scrub: 1,

            start: "top bottom",
            end: "bottom top",
            markers: false

        }
    })*/

}

export { createAnimation };
