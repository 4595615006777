import Proton from 'three.proton.js';
import * as THREE from 'three';

function createParticles(scene) {

    var proton = new Proton();
    //proton.addEmitter(createEmitter());
    proton.addRender(new Proton.SpriteRender(scene));
    //proton.addRender(new Proton.MeshRender(scene));

    return proton;
}

export { createParticles };
