import * as THREE from 'three';
import Proton from 'three.proton.js';
  
function buildParticles(scene, proton, emitter) {

    //var stats, clock, spring;

    proton.addEmitter(createEmitter());

    function createSprite() {
        var map = new THREE.TextureLoader().load("./assets/images/dot.png");
        var material = new THREE.SpriteMaterial({
            map: map,
            color: 0xff0000,
            blending: THREE.AdditiveBlending,
            fog: true
        });
        return new THREE.Sprite(material);
    }

    function createEmitter() {
        
        emitter.rate = new Proton.Rate(new Proton.Span(12, 15), new Proton.Span(.01, .1));
        emitter.addInitialize(new Proton.Body(createSprite()));
        emitter.addInitialize(new Proton.Mass(1));
        emitter.addInitialize(new Proton.Life(0.1, 0.5));
        emitter.addInitialize(new Proton.Position(new Proton.SphereZone(2)));
        emitter.addInitialize(new Proton.Velocity(10, new Proton.Vector3D(0, 1, 1), 30));
        emitter.addBehaviour(new Proton.RandomDrift(5, 5, 5, .05));
        //emitter.addBehaviour(new Proton.Alpha(1, 0.1));
        emitter.addBehaviour(new Proton.Scale(new Proton.Span(0.4, 0.7), 0));
        emitter.addBehaviour(new Proton.Gravity(5));
        emitter.addBehaviour(new Proton.Color('#FF0026', ['#ffff00', '#ffff11'], Infinity, Proton.easeOutSine));
        
        emitter.p.x = 20;
        emitter.p.y = -80;
        emitter.p.z = 150;
        //emitter.emit(); 
        //emitter.stopemit(); 
        return emitter;
    }

};

export { buildParticles };
