//import { createControls } from './systems/controls.js';
import { createRenderer } from './systems/renderer.js';
import { createParticles } from './systems/particle.js';
import { Resizer } from './systems/Resizer.js';
import { Loop } from './systems/Loop.js';

import { loadModels } from './components/models/models.js';
import { loadGallery } from './components/models/gallery.js';
import { loadStars } from './components/models/stars.js';
import { createCamera } from './components/camera.js';
import { createLights } from './components/lights.js';
import { createScene } from './components/scene.js';
import { createAnimation } from './components/animation.js';
import { buildParticles } from './components/particles.js';

import Proton from 'three.proton.js';

let camera;
//let controls;
let renderer;
let scene;
let loop;
let animation;
let proton;
let protonParticles;
let emitter;
let mainLight, ambientLight;

class World {
  constructor(container) {

    camera = createCamera();
    renderer = createRenderer();
    scene = createScene();
    proton = createParticles(scene);
    emitter = new Proton.Emitter();

    loop = new Loop(camera, scene, proton, renderer);

    container.append(renderer.domElement);
    //controls = createControls(camera, renderer.domElement);

    //loop.updatables.push(controls);

    const resizer = new Resizer(container, camera, renderer);

    // Observe a scene or a renderer
    if (typeof __THREE_DEVTOOLS__ !== 'undefined') {
      __THREE_DEVTOOLS__.dispatchEvent(new CustomEvent('observe', { detail: scene }));
      __THREE_DEVTOOLS__.dispatchEvent(new CustomEvent('observe', { detail: renderer }));
    }
  }

  async init() {
    let mobile = false;

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      mobile = true;
      //console.log('mobile');
    } else {
      mobile = false;
      //console.log('not mobile;');
    }

    const { rocket, stairs, phone, cloudOne, cloudTwo, cloudThree, sun } = await loadModels(scene,mobile);
    const { gallery } = await loadGallery(scene,mobile);
    const { stars } = await loadStars(mobile);
    const { ambientLight, mainLight } = createLights();

    // move the target to the center
    //controls.target.copy(rocket.position);
    
    scene.add(rocket, stairs, phone, sun, cloudOne, cloudTwo, cloudThree, stars);
    scene.add(ambientLight, mainLight);
    //scene.add(gallery);

    protonParticles = buildParticles(scene, proton, emitter);
    animation = createAnimation(mobile, gallery, rocket, stairs, phone, cloudOne, cloudTwo, cloudThree, sun, stars, ambientLight, mainLight, emitter);

  }

  start() {
    loop.start();
  }

  stop() {
    loop.stop();
  }

}


export { World };
