import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { setupModel } from './setupModel.js';
import * as THREE from 'three';

async function loadModels(scene,mobile) {

  //cubemap
  const path = '../../assets/images/stars/';
  const format = '.jpg';
  const urls = [
    path + 'px' + format, path + 'nx' + format,
    path + 'py' + format, path + 'ny' + format,
    path + 'pz' + format, path + 'nz' + format
  ];

  const reflectionCube = new THREE.CubeTextureLoader().load( urls );
  const refractionCube = new THREE.CubeTextureLoader().load( urls );
  refractionCube.mapping = THREE.CubeRefractionMapping;

  //scene = new THREE.Scene();
  //scene.background = reflectionCube;

  //materials
  const cubeMaterial3 = new THREE.MeshLambertMaterial( { color: 0xff6600, envMap: reflectionCube, combine: THREE.MixOperation, reflectivity: 0.3 } );
  const cubeMaterial2 = new THREE.MeshLambertMaterial( { color: 0xffee00, envMap: refractionCube, refractionRatio: 0.95 } );
  const cubeMaterial1 = new THREE.MeshLambertMaterial( { color: 0xffffff, envMap: reflectionCube } );

  const loader = new GLTFLoader();

  const [rocketData, stairsData, phoneData, cloudsDataOne, cloudsDataTwo, cloudsDataThree, sunData] = await Promise.all([
    loader.loadAsync('/assets/3d/rocket_ship_new.gltf'),
    loader.loadAsync('/assets/3d/stairs-2.gltf'),
    loader.loadAsync('/assets/3d/nokia-3.glb'),
    loader.loadAsync('/assets/3d/cloud-1.glb'),
    loader.loadAsync('/assets/3d/cloud-2.glb'),
    loader.loadAsync('/assets/3d/cloud-3.glb'),
    loader.loadAsync('/assets/3d/sun.glb'),
  ]);

  const material = new THREE.MeshLambertMaterial({color: 0xFFFFFF, transparent: false, opacity: 1});
  const sunMaterial = new THREE.MeshLambertMaterial({color: 0xffa500, transparent: false, opacity: 1});
  const sunCubeMaterial = new THREE.MeshLambertMaterial( { color: 0xffa500, envMap: reflectionCube, combine: THREE.MixOperation, reflectivity: 0.3 } );

  const rocket = setupModel(rocketData);
  rocket.name = "rocket";
  const stairs = setupModel(stairsData);
  stairs.name = "stairs";

  const phone = setupModel(phoneData);
  phone.name = "phone";

  const cloudOne = setupModel(cloudsDataOne);
  cloudOne.name = "cloudOne";
  const cloudTwo = setupModel(cloudsDataTwo);
  cloudTwo.name = "cloudTwo";
  const cloudThree = setupModel(cloudsDataThree);
  cloudThree.name = "cloudThree";

  const sun = setupModel(sunData);
  sun.name = "sun";

  /*const geoFloor = new THREE.BoxGeometry( 2000, 0.1, 2000 );
  const mshStdFloor = new THREE.Mesh( geoFloor, cubeMaterial1 );
  scene.add( mshStdFloor );
  mshStdFloor.name = "floor";
  mshStdFloor.position.set(0, -80, 0);
  mshStdFloor.rotation.set(0.080, 0, 0);*/


  if (mobile){

    /* rocket.rotation.set(-0.75, -0.25, 2.05); */
    rocket.position.set(5, -45, 150);
    rocket.scale.set(1.6,1.6,1.6);

    stairs.position.set(80, -15, 170);
    stairs.rotation.y = 3;
    stairs.scale.set(45,45,45);

    phone.position.set(5, -50, 150);
    phone.scale.set(1.5,1.5,0.75);

    /* RIGHT */
    cloudOne.position.set(20, 100, 0);
    cloudOne.rotation.y = 3;
    cloudOne.scale.set(8,8,8);

    /* LEFT BOTTOM */
    cloudTwo.position.set(-10, 150, 0);
    cloudTwo.scale.set(4,4,4);

    sun.position.set(10, -10, 100);

    /* LEFT */
    cloudThree.position.set(-50, 150, 0);
    cloudThree.scale.set(3,3,3);
    cloudThree.rotation.y = 3;


  } else {
    //console.log('NOT MOBILE');

    /* rocket.rotation.set(-0.75, -0.25, 2.05); */
    rocket.position.set(20, -65, 150);
    rocket.scale.set(3.6, 3.6, 3.6);

    stairs.position.set(60, -15, 170);
    stairs.rotation.y = 3;
    stairs.scale.set(45,45,45);

    phone.position.set(10, -50, 170);
    phone.scale.set(1.5,1.5,0.75);

    /* RIGHT */
    cloudOne.position.set(80, 100, 0);
    cloudOne.rotation.y = 3;
    cloudOne.scale.set(8,8,8);

    /* LEFT BOTTOM */
    cloudTwo.position.set(-10, 150, 0);
    cloudTwo.scale.set(4,4,4);

    /* LEFT */
    cloudThree.position.set(-20, 150, 0);
    cloudThree.scale.set(6,6,6);
    cloudThree.rotation.y = 0.4;

    sun.position.set(12, 5, 100);
    sun.scale.set(0,0,0);
  }

  cloudOne.traverse((o) => {
    if (o.isMesh) o.material = material;
  });

  cloudTwo.traverse((o) => {
    if (o.isMesh) o.material = material;
  });

  cloudThree.traverse((o) => {
    if (o.isMesh) o.material = material;
  });

  sun.traverse((o) => {
    if (o.isMesh) o.material = sunMaterial;
  });

  stairs.traverse((o) => {
    if (o.isMesh) o.material = cubeMaterial1;
  });

  return {
    rocket,
    stairs,
    phone,
    cloudOne,
    cloudTwo,
    cloudThree,
    sun,
  };
}

export { loadModels };
