import * as THREE from 'three';

async function loadGallery(mobile) {

  const gallery = new THREE.Group();
  
  /*console.log('GALLERY LOADING...');

  //const material = new THREE.MeshLambertMaterial({color: 0xFFFFFF, transparent: false, opacity: 1});
  const gallery = new THREE.Group();

  let loader = new THREE.TextureLoader();
  let texture = loader.load( '/assets/3d/textures/screengrab-01.jpg' );

  let mockup = new THREE.MeshPhongMaterial( { map: texture ,opacity: 1, reflectivity:0} );

  let object = new THREE.Mesh( new THREE.PlaneGeometry( 50, 100, 4, 4 ), mockup );
  object.position.set( -100, 0, 0 );
  object.rotation.set( 0, -0.2, 0 );
  gallery.add( object );

  let objectTwo = new THREE.Mesh( new THREE.PlaneGeometry( 50, 100, 4, 4 ), mockup );
  objectTwo.position.set( 0, 0, 20 );
  gallery.add( objectTwo );

  let objectThree = new THREE.Mesh( new THREE.PlaneGeometry( 50, 100, 4, 4 ), mockup );
  objectThree.position.set( 100, 0, 0 );
  objectThree.rotation.set( 0, 0.2, 0 );
  gallery.add( objectThree );

  gallery.position.set( 0, -150, 0 ); */

  return {
    gallery
  };
}

export { loadGallery };
