import * as THREE from 'three';

async function loadStars(mobile) {

  const stars = new THREE.Group();

  function addStar() {
    const geometry = new THREE.SphereGeometry(0.25, 24, 24);
    const material = new THREE.MeshLambertMaterial({color: 0xFFFFFF, transparent: true, opacity: 1});
    const star = new THREE.Mesh(geometry, material);

    const [x, y, z] = Array(3)
        .fill()
        .map(() => THREE.MathUtils.randFloatSpread(200));

    star.position.set(x, y, z);
    stars.add(star);
  }

  Array(200).fill().forEach(addStar);

  stars.position.y = 200;
  stars.position.z = 50;

  return {
    stars
  };
}

export { loadStars };
