import { PerspectiveCamera } from 'three';

function createCamera() {
  const camera = new PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 2000 );

  //Zoomed In
  //camera.position.set(0);
  camera.position.z = 200;

  //Zoomed Out
  /*camera.position.z = 400;
  camera.position.y = -100;*/

  return camera;
}

export { createCamera };
