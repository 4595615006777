import { Clock } from 'three';
import Proton from 'three.proton.js';

const clock = new Clock();
var tha = 0,
      R = 200;

class Loop {
  constructor(camera, scene, proton, renderer) {

    this.camera = camera;
    this.proton = proton;
    this.scene = scene;
    this.renderer = renderer;
    this.updatables = [];
  }

  start() {
    this.renderer.setAnimationLoop(() => {
      // tell every animated object to tick forward one frame
      this.tick();

      // render a frame
      this.renderer.render(this.scene, this.camera);
    });
  }

  stop() {
    this.renderer.setAnimationLoop(null);
  }

  tick() {
    // only call the getDelta function once per frame!
    const delta = clock.getDelta();

    // console.log(
    //   `The last frame rendered in ${delta * 1000} milliseconds`,
    // );

    this.proton.update();
    /* tha += .005;
    this.proton.emitters[0].p.x = Math.cos(tha) * R;
    this.proton.emitters[0].p.y = Math.sin(tha) * R;
    this.proton.emitters[0].rotation.x += 0.01;
    var x = Math.cos(tha) * 100;
    var y = Math.sin(tha) * 100; */
    
    //spring.reset(x, y, 100);
    //controls.update();

    //Proton.Debug.renderInfo(this.proton, 3);

    for (const object of this.updatables) {
      object.tick(delta);
    }
  }
}

export { Loop };
